$publicPath:'';

.shenhe-tab-box{
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 30px;
}
.shenhe-table-box {
  padding-left: 20px;
  padding-right: 20px;
}
.shenhe-form-box {
  margin-top: 15px;
}
